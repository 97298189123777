<template>
  <div class="heading_title">Crate Amounts</div>
  <div class="crates">
    <div class="container">
        <span class="title">Bmats</span>
        <div class="crate">
            <img src="/assets/images/resources/crate.png" alt="Bmat Crate Icon"/>
            <span v-if="factoryStore.factorySetting == 'factory'">
                {{ Math.ceil(orderStore.factoryCosts.bmat / 100) }}
            </span>
            <span v-if="factoryStore.factorySetting == 'mpf'">
                {{ Math.ceil(orderStore.mpfCosts.bmat / 100) }}
            </span>
        </div>
    </div>
    <div class="container">
        <span class="title">Rmats</span>
        <div class="crate">
            <img src="/assets/images/resources/crate.png" alt="Rmat Crate Icon"/>
            <span v-if="factoryStore.factorySetting == 'factory'">
                {{ Math.ceil(orderStore.factoryCosts.rmat / 20) }}
            </span>
            <span v-if="factoryStore.factorySetting == 'mpf'">
                {{ Math.ceil(orderStore.mpfCosts.rmat / 20) }}
            </span>
        </div>
    </div>
    <div class="container">
        <span class="title">Emats</span>
        <div class="crate">
            <img src="/assets/images/resources/crate.png" alt="Emat Crate Icon"/>
            <span v-if="factoryStore.factorySetting == 'factory'">
                {{ Math.ceil(orderStore.factoryCosts.emat / 40) }}
            </span>
            <span v-if="factoryStore.factorySetting == 'mpf'">
                {{ Math.ceil(orderStore.mpfCosts.emat / 40) }}
            </span>
        </div>
    </div>
    <div class="container">
        <span class="title">Hemats</span>
        <div class="crate">
            <img src="/assets/images/resources/crate.png" alt="Hemat Crate Icon"/>
            <span v-if="factoryStore.factorySetting == 'factory'">
                {{ Math.ceil(orderStore.factoryCosts.hemat / 30) }}
            </span>
            <span v-if="factoryStore.factorySetting == 'mpf'">
                {{ Math.ceil(orderStore.mpfCosts.hemat / 30) }}
            </span>
        </div>
    </div>
  </div>
</template>

<script setup>
    import { useOrderStore } from '@/stores/OrderStore';
    import { useFactoryStore } from '@/stores/FactoryStore';

    const orderStore = useOrderStore();
    const factoryStore = useFactoryStore();
</script>

<style scoped>
    .heading_title {
        display: block;
        width: 100%;
        border-bottom: 1px solid gray;
        padding: 10px 0;
        margin: 0 10px;
        font-size: 1.1rem;
    }
    .crates {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap; 
    }
    .container {
        display: flex;
        flex-direction: column;

        padding: 0 10px 10px 10px;
        width: 120px;
    }
    .title {
        padding-left: 0px;
    }
    .crate {
        display: flex;
        justify-content: space-between;
        align-items: center;

        width: 120px;
        background-color: #4D4D4D;
    }
    img {
        margin-left: 5px;
        width: 30px;
    }

    span {
        padding: 10px;
    }
</style>